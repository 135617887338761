import {useValtioSnapshot, valtioProxy} from '@/utils/valtio';
import {Navigate} from 'react-router';
import React from 'react';
import {useEffect, type FC} from 'react';
import {observer} from 'mobx-react-lite';

// this exists solely to allow redirects outside of remix context
export const globalNavigatorProxy = valtioProxy<string | null>(null);
export default function navigate(location: string) {
  globalNavigatorProxy.value = location;
}

export const GlobalNavigator: FC = observer(function GlobalNavigator() {
  const globalNavigator = useValtioSnapshot(globalNavigatorProxy);
  // HACK
  useEffect(() => {
    if (globalNavigator) {
      globalNavigatorProxy.value = null;
    }
  });

  if (globalNavigator) {
    const to = globalNavigator;
    return <Navigate to={to} />;
  }
  return null;
});
