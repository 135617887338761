import {AnimateOutDurationMs} from '@/components/core/Animation';
import type {StatusProps} from '@/components/core/Status';
import Status from '@/components/core/Status';
import Environment from '@/config/Environment';
import {motion, AnimatePresence} from 'framer-motion';
import {useValtioSnapshot, valtioProxy} from '@/utils/valtio';
import clsx from 'clsx';
import {observer} from 'mobx-react-lite';
import ViewportStore from '@/state/ViewportStore';
import {observable, runInAction} from 'mobx';

type StatusSpecProps = Pick<StatusProps, 'type' | 'content'>;

type StatusSpec = {
  id: number;
  props: Omit<StatusProps, 'index'>;
};

let statusId = 0;

const DisplayDurationMs = 2805;

if (Environment.IsPlaywright) {
  // allows playwright to clear these statuses programmatically without having to actually interact with the page.
  // this will speed up tests
  (window as any).livanClearStatuses = () => {
    runInAction(() => {
      statusSpecs.clear();
    });
  };
}

// speeds up tests
const playwrightDenominator = 6;
const baseFadeInDurationMs = 150;
const baseFadeOutDurationMs = 200;

const FadeInDurationMs = Environment.IsPlaywright
  ? baseFadeInDurationMs / playwrightDenominator
  : baseFadeInDurationMs;
const FadeOutDurationMs = Environment.IsPlaywright
  ? baseFadeOutDurationMs / playwrightDenominator
  : baseFadeOutDurationMs;

const statusSpecs = observable<StatusSpec | null>([]);

export function displayStatus(props: StatusSpecProps) {
  const id = ++statusId;
  const statusSpec: StatusSpec = {
    id,
    props,
  };
  runInAction(() => {
    statusSpecs.push(statusSpec);
  });

  setTimeout(() => {
    runInAction(() => {
      let otherStatusesExist = false;
      for (let i = 0; i < statusSpecs.length; i++) {
        const statusSpec = statusSpecs[i];
        if (id === statusSpec?.id) {
          statusSpecs[i] = null;
        } else if (statusSpec) {
          otherStatusesExist = true;
        }
      }
      if (!otherStatusesExist) {
        // empty entire array if none are left to start positioning over
        statusSpecs.clear();
      }
    });
  }, DisplayDurationMs);
}

const StatusContext = observer(function StatusContext({
  children,
}: {
  container?: Element | DocumentFragment;
  children: any;
}) {
  return (
    <>
      <ul className="StatusContext fixed flex flex-col items-center sm:items-end justify-center z-50 max-w-[100vw] sm:w-full sm:max-w-sm inset-x-0 sm:left-auto p-2 gap-2 pointer-events-none">
        <AnimatePresence initial={false}>
          {statusSpecs.map((statusSpec, i) => {
            const id = statusSpec?.id;
            if (!statusSpec) {
              return null;
            }
            return (
              <motion.li
                key={id || i}
                layout
                initial={{
                  opacity: 0,
                  x: ViewportStore.sm ? 100 : undefined,
                  scale: 0.7,
                  transition: {duration: FadeInDurationMs / 1000},
                }}
                animate={{opacity: 1, x: 0, scale: 1}}
                exit={{
                  opacity: 0,
                  x: 0,
                  scale: 0.7,
                  transition: {duration: FadeOutDurationMs / 1000},
                }}
              >
                <Status
                  index={i}
                  {...(statusSpec.props as Readonly<StatusSpec['props']>)}
                />
              </motion.li>
            );
          })}
        </AnimatePresence>
      </ul>
      {children}
    </>
  );
});

export default StatusContext;
