import {observer} from 'mobx-react-lite';
import clsx from 'clsx';
import type {CSSProperties, FC, ReactNode} from 'react';
import {CircleCheckIcon, InfoIcon, TriangleAlertIcon, XCircleIcon} from 'lucide-react';

const stylesByType = {
  success: {
    Icon: CircleCheckIcon,
    iconClass: 'text-green-500',
    borderClass: 'border-green-500',
  },
  info: {
    Icon: InfoIcon,
    iconClass: 'text-blue-400',
    borderClass: 'border-blue-400',
  },
  warning: {
    Icon: TriangleAlertIcon,
    iconClass: 'text-yellow-500',
    borderClass: 'border-yellow-500',
  },
  error: {
    Icon: XCircleIcon,
    iconClass: 'text-red-500',
    borderClass: 'border-red-500',
  },
};

export type StatusType = keyof typeof stylesByType;

export type StatusProps = {
  type: StatusType;
  content: ReactNode | (() => ReactNode);
  index: number;
  className?: string;
  style?: CSSProperties;
};

const Status: FC<StatusProps> = observer(function Status(props) {
  const {className, type = 'info', index} = props;
  let {content} = props;
  if (typeof content === 'function') {
    content = content();
  }

  const {Icon, iconClass, borderClass} = stylesByType[type];

  return (
    <div
      aria-live="assertive"
      className={clsx(
        `Status Status-type-${type}`,
        'rounded bg-white text-black shadow-lg border-t-4 p-2 pr-6 max-h-72',
        className,
        borderClass,
      )}
    >
      <div className="flex items-start">
        <div className="shrink-0">
          <Icon
            aria-hidden="true"
            className={clsx('size-6', iconClass)}
          />
        </div>
        <div className="ml-2 flex-1 min-w-0">{content}</div>
      </div>
    </div>
  );
});

export default Status;
