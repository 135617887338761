import {makeObservable, observable, computed, action, flow, runInAction} from 'mobx';

export type Breakpoint = 'none' | 'xs' | 'sm' | 'md' | 'lg' | 'xl';

const initialWidth = typeof window !== 'undefined' ? window.innerWidth : 0;
const initialHeight = typeof window !== 'undefined' ? window.innerHeight : 0;

class _ViewportStore {
  width = initialWidth;
  height = initialHeight;

  constructor() {
    makeObservable(this, {
      width: observable,
      height: observable,
      currentBreakpoint: computed,
      none: computed,
      xs: computed,
      sm: computed,
      md: computed,
      lg: computed,
      xl: computed,
    });
  }

  get currentBreakpoint(): Breakpoint {
    if (this.xs) return 'xs';
    if (this.sm) return 'sm';
    if (this.md) return 'md';
    if (this.lg) return 'lg';
    if (this.xl) return 'xl';
    return 'none';
  }

  get none() {
    return this.width < 375;
  }

  get xs() {
    return this.width >= 375;
  }
  get sm() {
    return this.width >= 640;
  }
  get md() {
    return this.width >= 768;
  }
  get lg() {
    return this.width >= 1024;
  }
  get xl() {
    return this.width >= 1280;
  }
}

const ViewportStore = new _ViewportStore();
export default ViewportStore;

if (typeof window !== 'undefined') {
  window.addEventListener('resize', (event) => {
    runInAction(() => {
      ViewportStore.width = (event.currentTarget as Window).innerWidth;
      ViewportStore.height = (event.currentTarget as Window).innerHeight;
    });
  });

  window.addEventListener('orientationchange', (event) => {
    runInAction(() => {
      ViewportStore.width = (event.currentTarget as Window).innerWidth;
      ViewportStore.height = (event.currentTarget as Window).innerHeight;
    });
  });
}
